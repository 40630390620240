import { css } from '@emotion/css'

// You have to restart dev to see new fonts

const colorBase = "#15171a"

const titleemotionstyle = css`

.content-title {
    margin-top: 2rem;
    font-size: 8vw;
    font-family: "NewsGothic", sans-serif;
    line-height: 1.15;
}

h2  {
    font-size: 6vw;
    color: ${colorBase};
    margin: 1.5em 0 .5em;
    font-weight: 700;
    line-height: 1.15;
}

h3 {
    font-size: 1.3rem;
    font-weight: 500;
    margin: 1.5em 0 .5em;
}

h4 {
    font-size: 1rem;
    font-weight: 500;
    margin: 1.5em 0 .5em;
}

@media (min-width:768px){
    .content-title  {
    font-size: 4rem;
    }

    h2 {
        /* color: red; */
        font-size: 1.75rem;
    }
}
`;

export default titleemotionstyle;