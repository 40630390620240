const tags = ['Blog']

// TODO - Is article meta going to pull article data as well?
export const siteName = "White Owl Education"

export const blogPost = {
    id: "2021-08-11-00",
    created_at: "2021-08-11T00:00:00.000-00:00",
    updated_at: "2021-08-11T00:00:00.000-00:00",
    page: {
        url: "https://www.whiteowleducation.com/blog/2021/08/05/explainable-ai-overview/"
    },
    author: {
        type: "Person",
        name: "White Owl Education",
        author_image_url: "https://whiteowleducation.com/images/authors/white-owl-education/white-owl-education-author-face.jpg",
        pathname: "images/authors/white-owl-education/white-owl-education-author-face.jpg",
        author_image_description: "Picture of Ralph Brooks, CEO of White Owl Education"
    },
    title: "Use Explainable AI to Prove Image Classification Works",
    publisher: {
        name: siteName,
        url: "https://whiteowleducation.com/images/theme/header/logo/wo-logo-transparent.png"
    },
    description: "Learn about heatmaps for image classification to pass the GCP Machine Learning Engineer exam!",
    image: {
        url: "https://whiteowleducation.com/images/blog/2021/08/05/card-main-image.png",
        description: "Pictures showing how a heatmap (Integrated Gradients) identifies that a background is not part of what is needed to classify an image of a uniform."
    },
    internal_featured_image: {
        pathname: "images/blog/2021/08/05/main-page-image.png",
        description: "Picture of Grace Hopper and another picture where Integrated Gradients identifies relevant pixels with a heatmap."
    },
    keywords: tags[0],
    featured: "no",
    read_time_minutes : "20",
    visibility: "public"
}