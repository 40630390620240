import { css } from '@emotion/css'



const questiononeemotionstyle = css`

.q1container 
{
    width: 80%;
    display: grid;
    justify-content:center;
    margin:auto;
}
.q1 {
    color: rgb(54, 54, 54);
    /* I need a way to center the box */
    height: 100%;
    border-radius: 0.25rem;
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
    background-color: rgba(193, 194, 196, 0.3);
    
}

.q1 .question {
    font-family: 'Google Sans', Roboto, Helvetica, Arial, sans-serif;
    font-display: swap;
    padding-top: 2rem;
    padding-left: 1rem;

}

.q1 .question p {
    width: 96% !important;
}

.q1 label {
    display: inline-block;
    vertical-align: middle;
    padding-left: 1rem;
    padding-bottom: 2rem;
    width: 96%
}

.q1 input {
    margin-right: 2rem;
}

.q1 .feedback a{
    color: black;
    background-color: whitesmoke;
    text-decoration: none;
}

/* .redtext {
    color: red;
    font-weight: 700;
}

.greentext {
    color: green;
    font-weight: 700;
} */

.main-content img {
    /* height: 200px;
    aspect-ratio: auto; */
    margin: 0 0 0;
    width: 100%;
    object-fit: cover;
}


/* Make sure li does not get cut off
the problem is that there is a container that thinks it can be wider than viewport
*/
/* .main-content li {
    width: 90vw;
} */

`;

export default questiononeemotionstyle;